<template>
  <v-responsive>
    <SubMenuAnalysisMobile v-if="$vuetify.breakpoint.smAndDown"></SubMenuAnalysisMobile>
    <v-container :fluid="$vuetify.breakpoint.lgAndDown" style="max-width:1440px;"> 
      <v-row>
        <v-col cols="12" sm="3" flat tile v-if="$vuetify.breakpoint.mdAndUp">
          <v-container class="pa-0">
            <v-card class="d-flex justify-space-between" flat tile>
              <v-card class="pa-0 pl-6" flat tile>
                <h2
                  class="
                    bx_pagetitle
                    float-left
                    mt-5
                    mb-3
                    blue--text
                    text--darken-4
                  "
                >
                  {{ breadcrumbs[1].text }}
                </h2>
              </v-card>
            </v-card>
          </v-container>
          <v-divider></v-divider>
          
          <SubMenuAnalysis></SubMenuAnalysis>


          <v-row class="my-0 mx-0" style="background: #fff">
            <v-col>
                  <GlobalSideBannerBottom></GlobalSideBannerBottom>
            </v-col>
          </v-row>



        </v-col>
        <v-col cols="12" sm="12" md="9" :class="$vuetify.breakpoint.smAndDown? 'mt-0 pt-0':''">
          <v-container class="pa-0" v-if="$vuetify.breakpoint.mdAndUp">
            <v-card class="d-flex justify-space-between" flat tile>
              <v-card class="pa-0" flat tile>
                <h2 class="bx_pagetitle float-left mt-5 mb-3">
                  
                  {{ breadcrumbs[2].text }} 
                </h2>
              </v-card>
              <v-card class="pa-0" flat tile v-if="$vuetify.breakpoint.mdAndUp">
                <v-breadcrumbs :items="breadcrumbs" class="float-right mt-2">
                  <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-card>
            </v-card>
          <v-divider></v-divider>
          </v-container>

          <v-row class="my-3 mx-0" no-gutters style="background: #fff">
            <v-col>
                  <GlobalSubBannerTop></GlobalSubBannerTop>
            </v-col>
          </v-row>


          <v-sheet min-height="58vh">
            <!--  -->

            <v-responsive
              class="my-5 mx-0"
              style="
                background: #f2f2f2;
                border: 1px solid #ededed;
                border-radius: 4px;
              "
            >
              <div
                style="background: #595d69; padding: 10px 15px; color: #fff;font-size:11px'font-weight:bold;"
              >
                고정 수 조합 도구 이용 방법
              </div>
              <ul
                style="
                  background: #f2f2f2;
                  color: #333;
                  font-size: 12px;
                  padding-top: 10px;
                  padding-bottom: 10px;
                "
              >
                <li style="padding: 5px 0">
                  <strong>고정 수 완전조합</strong> : 나올 것 같은 숫자를
                  고정시켜 모든 경우의 수를 조합한 당첨 번호를 추출해 줍니다.
                </li>
                <li style="padding: 5px 0">
                  <strong>사용방법</strong> : 고정 수 선택 ▶ 조합 갯수 설정 ▶
                  조합하기 ▶ 체크된 조합 확인 ▶ 조합저장
                </li>
              </ul>
            </v-responsive>


            <div class="">
              <div class="text-center mt-5">
                <!--
                <v-text-field
                  label="고정수(,로 구분)"
                  placeholder="1,2,3,4,5"
                  required
                  v-model="fixednumbers8"
                ></v-text-field>
-->
                <LottoSelect
                  style="max-width: ; margin: 0 auto"
                  :min="8"
                  :max="12"
                ></LottoSelect>

                <v-row class="mt-5" no-gutters>
                  <v-col cols="12" sm="6" md="6">
                <v-sheet 
                  outlined
                  color="grey lighten-4"
                  class="pa-2 mx-auto"
                  style="
                    font-size: 14px;
                    font-weight: bold;
                    border-radius: 6px;
                    color: #888;
                    text-align: center;
                  "
                  >
                  이번 주 남은 행운 번호 받기 <br>
                  <strong style="font-size: 20px; color: #595d69">{{$store.state.systemOption.uid_chance}}</strong>
 /
{{$store.state.systemOption.service_weeks_get_count}}
                  

                </v-sheet>
                <v-sheet v-if="false"
                      outlined
                      color="grey lighten-4"
                      class="pa-2 mx-auto "
                      style="
                        font-size: 14px;
                        font-weight: bold;
                        border-radius: 6px;
                        color: #888;
                        text-align: center;
                      "
                      >보유포인트 :
                      <strong style="font-size: 20px; color: #595d69"
                        >1,000</strong
                      >
                      <br />

                      <v-btn
                        small
                        color="blue-grey"
                        class="ma-2 white--text"
                        @click="loader = 'loading3'"
                      >
                        포인트 충전
                      </v-btn>
                    </v-sheet>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-btn
                      :disabled="$store.state.systemOption.uid_chance<1"
                      color="blue darken-4 white--text mt-2"
                      x-large
                      class="font-weight-bold"
                      @click="createLottoNums3"
                    >
                      고정 수 8+ 완전조합 받기
                    </v-btn>
                    
                  </v-col>
                </v-row>

                <p class="text--disabled my-5">
                  <small>
                    당사의 분석시스템은 누적패턴을 분석/필터링한 정보제공만을
                    목적으로 하며, 당첨확률 개선서비스가 아니므로 서비스 이용
                    과정에서 기대이익을 얻지 못하거나 발생한 손해 등에 대한
                    최종책임은 서비스 이용자 본인에게 있습니다.
                  </small>
                </p>
              </div>

              <v-row class="my-3 mx-0" no-gutters style="background: #fff">
                <v-col>
                     <GlobalSubBannerBottom></GlobalSubBannerBottom>
                </v-col>
              </v-row>

              
              <LottoResult
                v-if="resultNumbers.length > 0"
                :title="'완전조합 '"
                class="mt-10"
              ></LottoResult>
            </div>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </v-responsive>
</template>

<script>

export default {
  name: "Analysis",
  title: "고정 수 8~12개 완전조합",

  components: {
    //HelloWorld,
  },
  data: () => ({
    checkNums: null,
    e1: 1,

    breadcrumbs: [
      { text: "홈" },
      { text: "로또분석 시스템" },
      { text: "고정 수 8개+ 완전조합" },
      //{ text: "당첨 통계", icon: "mdi-menu-right" },
    ],
    selectedItem: 0,
    items: [
      { text: "20 수 번호 받기", icon: "mdi-menu-right" },
      { text: "고정 수 7개 완전조합", icon: "mdi-menu-right" },
      { text: "고정 수 8개+ 완전조합", icon: "mdi-menu-right" },
    ],
    admins: [
      ["Management", "mdi-account-multiple-outline"],
      ["Settings", "mdi-cog-outline"],
    ],
    cruds: [
      ["고정 수 7개 완전조합", "mdi-menu-right"],
      ["고정 수 8+개 완전조합", "mdi-menu-right"],
    ],

    lottoNumbers: null,

    fixednumbers7: "5,11,13,32,33,36,41",
    lottoNumbers2: null,

    fixednumbers8: "5,11,13,32,33,36,41,44",
    lottoNumbers3: null,

    amenities: [1, 4],
  }),
  computed: {
    resultNumbers: {
      get() {
        return this.$store.state.resultNumbers;
      },
      set(payload) {
        this.$store.commit("UPDATE_RESULT_NUMBERS", payload);
      },
    },
  },

  watch: {
    selectedItem() {
      //alert(this.selectedItem);
      //this.createLottoNums2 = [];
      //this.createLottoNums3 = null;

      this.$store.commit("UPDATE_SELECT_NUMBERS", []);
      this.$store.commit("UPDATE_RESULT_NUMBERS", []);
    },
    resultNumbers(){
    this.$getServerInfo(this.$store.state.systemOption.uid);

    },
  },

  methods: {
    setSubMenu(no) {
      this.selectedItem = no;
    },

    createLottoNums() {
      this.$axios
        .get("//lotto7777.whynot.so/lottoapp/?mode=create&count=20")
        .then((res) => {
          console.log(res);

          if (res.status === 200) {
            //this.paging = res.data.paging;
            //this.no = this.paging.totalCount - ((this.paging.page-1) * this.paging.ipp);

            //this.classes = res.data;
            //this.lottoNumbers = res.data;
            this.resultNumbers = res.data;
          } else {
            alert("실행중 실패했습니다.\n다시 이용해 주세요.");
          }

          if (this.firstLoad) this.firstLoad = false;
          this.loading = false;
        })
        .then((err) => {
          console.log(err);

          this.loading = false;
        });
    },
    createLottoNums2() {
      console.log(this.$store.state.selectedNumbers.length);
      console.log(this.max);

      if (this.$store.state.selectedNumbers.length != 7) {
        this.$store.commit("pop", {
          msg: "고정 수 조합 8~12개를 모두 선택해주세요.",
          color: "",
        });
        return false;
      }

      //alert(this.$store.state.selectedNumbers.join(','));
      this.$axios
        .get(
          "//lotto7777.whynot.so/lottoapp/absolute_number.php?includeNumbers=" +
            this.$store.state.selectedNumbers.join(",")+`&uid=${this.$store.state.systemOption.uid}`
        )
        .then((res) => {
          console.log(res);

          if (res.status === 200) {
            //this.paging = res.data.paging;
            //this.no = this.paging.totalCount - ((this.paging.page-1) * this.paging.ipp);

            //this.classes = res.data;
            //this.lottoNumbers2 = res.data;

            this.resultNumbers = res.data;
          } else {
            alert("실행중 실패했습니다.\n다시 이용해 주세요.");
          }

          if (this.firstLoad) this.firstLoad = false;
          this.loading = false;
        })
        .then((err) => {
          console.log(err);

          this.loading = false;
        });
    },
    createLottoNums3() {
      if (this.$store.state.selectedNumbers.length < 8) {
        this.$store.commit("pop", {
          msg: "고정 수 조합 8개이상 선택해주세요.",
          color: "",
        });
        return false;
      }
      if (this.$store.state.selectedNumbers.length > 12) {
        this.$store.commit("pop", {
          msg: "고정 수 조합은 12개까지만 선택 가능합니다.",
          color: "",
        });
        return false;
      }

      this.$axios
        .get(
          "//lotto7777.whynot.so/lottoapp/absolute_number.php?includeNumbers=" +
            this.$store.state.selectedNumbers.join(",")
        )
        .then((res) => {
          console.log(res);

          if (res.status === 200) {
            //this.paging = res.data.paging;
            //this.no = this.paging.totalCount - ((this.paging.page-1) * this.paging.ipp);

            //this.classes = res.data;
            //this.lottoNumbers3 = res.data;

            this.resultNumbers = res.data;
          } else {
            alert("실행중 실패했습니다.\n다시 이용해 주세요.");
          }

          if (this.firstLoad) this.firstLoad = false;
          this.loading = false;
        })
        .then((err) => {
          console.log(err);

          this.loading = false;
        });
    },
  },
};
</script>
<style>
.asdf {
  color: #85ff4d;
}
</style>
